.popup .form-top.clearfix {
    display: flex;
    align-items: center;
}
.popup .form-top.clearfix > * > * {
    float: left;
    text-align: left;
}
.popup .form-top.clearfix>div[class*=col-] {
    flex: 1 0 auto;
    width: auto !important;
    max-width: 100%;
    text-align: left;
    padding-bottom: 12px;
}
.popup .form-top.clearfix>div[class*=col-] * {
    text-align: left;
}
#confirmation_qs_header {
    background: #000;
    color: #FFF;
    margin-bottom: 25px;
}
#confirmation_qs_text {
    font-size: 1.2em;
    padding: 8px;
    text-align: center;
}

.max-rnt-image .listing-logo {
    width: 100% !important;
    height: auto;
}
.max-rnt-description.rnt-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

/* Click Wall Card Styling */
#target .max-rnt-listing {
    display: flex;
    padding: 15px;
    border-radius: 4px;
    cursor: pointer;
    transition-property: box-shadow, transform;
    transition-duration: .15s;
    max-width: 1000px;
    margin: 0 auto;
    min-height: 140px;
    background-color: white;
    /* flex-direction: column; */
    /* align-items: center; */
}
#target .row.max-rnt-listing > div {
    /* flex: 0 0 100% !important; */
    text-align: center;
    /* max-width: 100% !important; */
    display: flex;
    justify-content: center;
}
#target .max-rnt-button {
    margin-right: auto !important;
}
#target .frame-headline-color {
    text-align: left !important;
}
.popup .step-confirmation .confirmation {
    background-color: inherit;
    border: 0;
}
.popup .step-confirmation .confirmation > h2 {
    background-color: #49B57E;
    color: #fff;
    font-size: 18px;
    padding-top: 25px;
    padding-bottom: 28px;
    font-weight: normal;
    margin: 0;
}
/* End Click Wall Card Style */

@media only screen and (max-width: 1199px) {
    .max-rnt-description.rnt-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }
}
@media only screen and (max-width: 991px) {
    #target .max-rnt-listing {
        display: flex;
        flex-direction: column;
    }
    #target .max-rnt-listing > div {
        max-width: 100%;
        width: 100%;
        display: flex;
    }
    #target .max-rnt-listing > div > * {
        margin-left: auto;
        margin-right: auto;
    }
    .max-rnt-description.rnt-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .max-rnt-description.rnt-section > * {
        text-align: center;
    }
    .popup .step-button .btn-linebreak {
        display: block !important;
    }
    .max-rnt-image {
        justify-content: center;
    }
    .max-rnt-image .listing-logo {
        width: 140px !important;
    }
    .max-rnt-button.rnt-section {
        display: flex;
        justify-content: center;
    }
}
@media only screen and (max-width: 768px) {
    .max-rnt-description.rnt-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .max-rnt-description.rnt-section > * {
        text-align: center;
    }
    .popup .step-button .btn-linebreak {
        display: block !important;
    }
    .max-rnt-image {
        justify-content: center;
    }
    .max-rnt-image .listing-logo {
        width: 140px;
    }
    .max-rnt-button.rnt-section {
        display: flex;
        justify-content: center;
    }
}
@media only screen and (max-width: 568px) {
    .popup .form-top > div {
        padding: 0 8px 0 0;
    }
}
@media only screen and (max-width: 568px) {
    .popup .form-top.clearfix * {
        font-size: 10px;
    }
    .popup .form-top > .col-3 {
        font-size: 10px !important;
    }
}
