@font-face {
    font-family: VisbyCF;
    font-weight: bold;
    src: url("../fonts/VisbyCF-Bold.otf") format("opentype");
}

@font-face {
    font-family: VisbyCF;
    src: url("../fonts/VisbyCF-Regular.otf") format("opentype");
}

@font-face {
    font-family: VisbyCF;
    font-weight: lighter;
    src: url("../fonts/VisbyCF-Light.otf") format("opentype");
}

html, html body, body {
    font-family: 'VisbyCF', serif;
    font-size: 18px;
    color:#000;
    background:#c2c1bd;
}

#header {background:#FFF;}
#footer {background:#FFF;}
#footer .footer-disclaimer p {
    font-size: 12px;
    line-height: 16px;
    color: #777777;
    display: block;
    text-align: center;
}
#footer .footer-links {
    display: table;
    margin: 40px auto 0;
    color: #000000;
    list-style: none;
    padding: 0;
    font-size: 16px;
}
#footer .footer-links li {
    display: inline-block;
    margin-left: 22px;
    margin-right: 22px;
}
#footer .text-black-50 {
    color: rgba(0,0,0,.5)!important;
}
#footer .pointer-cursor,
#header .pointer-cursor,
.submit-bottom .pointer-cursor {
    cursor: pointer;
    text-decoration: underline !important;
}
.submit-bottom a {
    color: #007bff !important;
}
.submit-bottom {
    color: #777777;
    font-size: 13px;
}
#survey {
    background:url('../images/survey-bg.jpg') no-repeat center center;
}
#survey-place-holder {
    background:#FFF;
    border-radius:10px;
    min-height:500px;
}

#progressbar {margin-top:20px;}

#mainLogo {
    display: block;
    width: 100%;
    margin: 10px auto;
    text-align: center;
}

#sectionProgress {font-weight:bold; height:22px;}

/* Mark input boxes that gets an error on validation: */
.invalid {
    background-color: #ffdddd !important;
}

.best-content2 {
    background-color: #000;
    color: #FFF;
}

.question {margin: 30px 0px 30px 0px;}

.question p {
    font-weight: bold;
    font-size: 25px;
    color:#174896;
    line-height: 1.25em;
    margin-bottom: 15px;
}

.btn-350 {
    max-width:350px; 
    text-align: center;
    margin:5px auto;
}

.question small {font-size:1em; line-height: 15px;}

.buttons {
    display: flex;
    justify-content: center;
    list-style: none;
    margin:0px;
    padding:0px;
    justify-content: center;
}

.buttons div {
    text-align: center;
    width: 150px;

}

@media(hover) {
    .buttons div span.icon:hover {
        box-shadow: 2px 0px 25px 0px rgba(125, 209, 236, 0.5) inset;
        -webkit-box-shadow: 2px 0px 25px 0px rgba(125, 209, 236, 0.5) inset;
        -moz-box-shadow: 2px 0px 25px 0px rgba(125, 209, 236, 0.5) inset;
        transition: transform .3s ease-in-out;
    }
}

.buttons div.active span.icon {
    border: 1px solid #174896;
    background-color:#174896;
}

.buttons div span {
    display: block;
}

.buttons div span.icon {
    margin: 20px auto 0;
    height:75px; 
    width:75px;
    background-size:70%;
    background-position: center;
    display:block;
    border:#174896 1px solid;
    border-radius: 50%;
    cursor: pointer;
}
.buttons div.active span.icon {
    background-size:70%;
    background-position: center;
}

.buttons div span.text {
    margin: 5px;
    line-height: 22px;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: .3px;
    color: #174896;
    cursor: pointer;
}

.backBtn {
    border: none;
    color: #0F60B4;
    font-weight: 600;
    background-color: transparent;
    transition: 0s;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

@media(hover) {
    .backBtn:hover {
        color: #0F60B4;
        background-color: #E9F0FD;
        /* border: 1px solid #0F60B4; */
    }
}

.continueBtn {
    border: none;
    color: #FFF;
    font-weight: 600;
    background-color: #0F60B4;
    transition: 0s;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

@media(hover) {
    .continueBtn:hover {
        color: #FFF;
        font-weight: 600;
        background-color: #0069D9;
        transition: 0s;
    }
}

.credit-score {
    margin:0px auto;
    padding:0px;
    text-align:center;
    max-width:300px;
}

.credit-score li {
    list-style: none;
    margin: 8px 0px;
    border:#174896 1px solid;
    border-radius:5px;
    color:#174896;
    font-weight:bold;
    padding:8px;
    cursor: pointer;
}

.credit-score li.active {
    background-color:#174896;
    color:#FFF;
}

@media(hover) {
    .credit-score li.active:hover {
        background-color: #3b68af;
        color: #FFF;
    }

    .credit-score li:hover {
        border: #006aff 1px solid;
        color: #006aff;
        background-color: #e0edff;
    }
}

.disclaimer {font-size:11px; line-height: 1em; font-family: arial;}

.form-control-zip {
    padding: 0.675rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.property-price-value {
    
}

.refinance span.icon {
    /* background:url('../images/refinance.png') no-repeat; */
}
.buy-a-home span.icon {
    /* background:url('../images/buy-a-home.png') no-repeat; */
}

.primary-residence span.icon {
    background:url('../images/primary-residence.png') no-repeat;
}
.secondary-home span.icon {
    /* background:url('../images/secondary-home.png') no-repeat; */
}
.inventment-property span.icon {
    /* background:url('../images/inventment-property.png') no-repeat; */
}

.single-family span.icon {
    background:url('../images/single-family.png') no-repeat;
}
.single-family.active span.icon {
    background:url('../images/single-family-active.png') no-repeat;
}
.condominium-home span.icon {
    background:url('../images/condominium-home.png') no-repeat;
}
.condominium-home.active span.icon {
    background:url('../images/condominium-home-active.png') no-repeat;
}
.town-home span.icon {
    background:url('../images/town-home.png') no-repeat;
}
.town-home.active span.icon {
    background:url('../images/town-home-active.png') no-repeat;
}
.mobile-home span.icon {
    background:url('../images/mobile-home.png') no-repeat;
}
.mobile-home.active span.icon {
    background:url('../images/mobile-home-active.png') no-repeat;
}
.duplex-home span.icon {
    /* background:url('../images/duplex-home.png') no-repeat; */
}

.excellent span.icon {
    /* background:url('../images/excellent.png') no-repeat; */
}
.good span.icon {
    /* background:url('../images/good.png') no-repeat; */
}
.average span.icon {
    /* background:url('../images/average.png') no-repeat; */
}
.poor span.icon {
    /* background:url('../images/poor.png') no-repeat; */
}

.bf-no span.icon {
    /* background:url('../images/bf-no.png') no-repeat; */
}
.bankruptcy span.icon {
    /* background:url('../images/bankruptcy.png') no-repeat; */
}
.foreclosure span.icon {
    /* background:url('../images/foreclosure.png') no-repeat; */
}
.bf-both span.icon {
    /* background:url('../images/bf-both.png') no-repeat; */
}

.not-too-bad span.icon {
    /* background:url('../images/not-too-bad.png') no-repeat; */
}
.some-issues span.icon {
    /* background:url('../images/some-issues.png') no-repeat; */
}
.major-issues span.icon {
    /* background:url('../images/major-issues.png') no-repeat; */
}

.yes span.icon {
    background:url('../images/yes.png') no-repeat;
}
.no span.icon {
    background:url('../images/no.png') no-repeat;
}

.yes.active span.icon {
    background:url('../images/yes-active.png') no-repeat;
}
.no.active span.icon {
    background:url('../images/no-active.png') no-repeat;
}

.maybe span.icon {
    /* background:url('../images/maybe.png') no-repeat; */
}

.self-employed-yes span.icon {
    background:url('../images/yes.png') no-repeat;
}
.self-employed-no span.icon {
    background:url('../images/no.png') no-repeat;
}

.employed span.icon {
    /* background:url('../images/employed.png') no-repeat; */
}
.self-employed span.icon {
    /* background:url('../images/self-employed.png') no-repeat; */
}
.retired span.icon {
    /* background:url('../images/retired.png') no-repeat; */
}
.other span.icon {
    /* background:url('../images/other.png') no-repeat; */
}

.price-value {
    font-size:1.5em;
}

.range-min {
    float: left; 
    font-size:18px;
}
.range-max {
    float: right; 
    font-size:18px;
}

.slider.slider-horizontal {
    width:100%
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;  
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider-handle {
    background-color: #1966AD;
    background-image: -webkit-linear-gradient(top, #1966AD 0%, #1966AD 100%);
    background-image: -o-linear-gradient(top, #1966AD 0%, #1966AD 100%);
    background-image: linear-gradient(to bottom, #1966AD 0%, #1966AD 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#38b560', endColorstr='#157f37', GradientType=0);
    filter: none;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background-color: #18843b;
    background-image: linear-gradient(to bottom,#18843b 0,#aed7bb 100%);
    cursor: pointer;
}
  
.slider::-moz-range-thumb {
    cursor: pointer;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #18843b;
    background-image: linear-gradient(to bottom,#18843b 0,#aed7bb 100%);
    cursor: pointer;
}

.slider-handle {
    cursor: pointer;
    width:30px; 
    height: 30px; 
    top:-5px; 
    border:#fff 3px solid;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
}
.slider-selection {
    background:#1966AD;
}
.slider-track-high {
    background:#a4b9cd
}

.section-hide {display:block}
.section-show {display:block}

.parsley-errors-list {list-style-type: none; margin:0px; padding:0px;}
.parsley-errors-list li {color:#C00; font-weight: bold; font-size:14px}

#finance-survey > .container > .row {
    justify-content: center;
}
#finance-survey .step-item {
    background-color: #FFF;
    border-radius: 10px;
    min-height: 500px;
}

/* modal styling for pivacy policy and terms of use */
.contentModalText {
    text-align: left;
    background-color: #FFFF;
    padding: 16px;
}
.contentModalText h1 {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;
    color: #000;
}
.contentModalText h2 {
    font-size: 1.53125rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2em;
    color: #000;
}
.contentModalText h3 {
    font-size: 1.3125rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2em;
    color: #000;
}
.contentModalText h4 {
    font-size: 1.09375rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2em;
    color: #000;
}
.contentModalText p {
    font-size: 14px;
    line-height: 1.5em;
    color: #495057;
    margin-top: 0;
    margin-bottom: 1rem;
}
.contentModalText .header {
    padding: 20px 20px 20px;
    background: #0a5ca9;
    color: #FFFFFF;
    width: 100%;
}
.contentModalText .header h3 {
    text-transform: uppercase;
    font-size: 22px;
}

.contentModalText table,
th,
td {
    border: 1px solid black !important;
    border-collapse: collapse !important;
    font-size: 14px !important;
    text-align: left;
    padding: 5px;
    color: #495057;
    line-height: 1.4em;
    margin-bottom: 10px;
}

.contentModalText th {
    color: #495057;
    font-weight: 700;
}
.contentModalText li {

    list-style-type: disc !important;
}
.contentModalText ul {
    margin-left: 10px !important;
    font-size: 14px !important;
    line-height: 1.5em !important;
    color: #495057 !important;
    margin-bottom: 10px !important;
}
.contentModalText ol {
    margin-left: 10px !important;
    font-size: 14px !important;
    line-height: 1.5em !important;
    color: #495057 !important;
    margin-bottom: 10px !important;
} 

@media (max-width: 650px)
{
    .step-item {
        background-color: rgba(255,255,255,0.8) !important;
    }

    .question p {
        font-weight: bold;
        font-size: 22px;
    }

    .buttons {
        overflow-wrap: break-word;
        flex-wrap: wrap;
    }

    .buttons button span.icon {
        float: left;
        background-repeat: no-repeat;
        background-position: 0 0;
        margin-top: 1px;
        height: 40px;
        width: 40px;
    }

    .buttons button {
        /* background-image: url('../images/arrow.png'); */
        background-repeat: no-repeat;
        background-position: right 20px top 25px;
        max-width: 450px;
        margin: 0 auto 15px;
        display: block;
        text-align: left;
        background-color: #fff;
        padding: 15px 20px;
        width: 100%;
        min-height: auto;
    }

    .buttons button span.text {
        line-height: 22px;
        float: left;
        font-size: 17px;
        padding-left: 5px;
        font-weight: 400;
        margin-top: 8px;
        letter-spacing: .02em;
        height:auto;
    }
    .input-holder {margin: 0px 0px;}
    .section-question {margin: 0px 40px;}
}

.contentModalOverlay {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.contentModal {
    position: fixed;
    z-index: 1000;
    width: 800px;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    background: #fff;
    overflow-y: auto;
    border-radius: 4px;
    outline: none;
    margin: 20px;
    max-height: 100vh;
    padding: 0;
    border: 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}

@media only screen and (max-width: 1000px) {
    .contentModal {
        height: 80%;
        width: 90%;
        margin: 0;
    }
}

@media (max-width: 768px) {
    .contentModalHeader .row {
        flex-direction: column-reverse;
    }
    .contentModalHeader .col-auto {
        flex: 0 0 100%;
        width: 100%;
    }
    .contentModalHeader .col-auto h3 {
        font-size: 1rem;
    }
}

@media (max-width: 420px) {
    .buttons > div {
        width: 120px;
    }
}

.contentModalHeader {
    background-color: #174896;
    color: white;
    padding: 20px;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
}

.contentModalText {
    padding: 20px;
    color: #212529;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}
.contentModalText h1 {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;
    color: #000;
}
.contentModalText h2 {
    font-size: 1.53125rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2em;
    color: #000;
}
.contentModalText h3 {
    font-size: 1.3125rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2em;
    color: #000;
}
.contentModalText h4 {
    font-size: 1.09375rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2em;
    color: #000;
}
.contentModalText p {
    font-size: 14px;
    line-height: 1.5em;
    color: #495057;
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 500;
}

.contentModalText ul {
    list-style-type: disc;
}

.contentModalText table,
th,
td {
    border: 1px solid black !important;
    border-collapse: collapse !important;
    font-size: 14px !important;
    text-align: left;
    padding: 5px;
    color: #495057;
    line-height: 1.4em;
    margin-bottom: 10px;
}

.contentModalText th {
    color: #495057;
    font-weight: 700;
}

.form-button button.btn-blue {
    background-color: #174896;
    border-color: #174896;
    width: 120px;
    margin: 0 auto 0 0;
    padding: 0.3rem 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #fff;
}

.step-errors {
    background-color: #ea5555;
    color: #FFF;
    font-size: 14px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 12px;
}

.infoToolTip{
    width: 200px !important;
    font-size: 16px !important;
    line-height: 16px !important;
    font-weight: normal !important;
}

.formContainer {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

@media (max-width: 650px) {
    .formContainer {
        padding-top: 0;
        padding-bottom: 0;
    }

    .containerInFormContainer {
        max-width: unset !important;
    }

    .blockWithImage {
        padding-top: 35px !important;
    }

    .table-responsive>.table>tbody>tr>td, .table-responsive>.table>tbody>tr>th, .table-responsive>.table>tfoot>tr>td, .table-responsive>.table>tfoot>tr>th, .table-responsive>.table>thead>tr>td, .table-responsive>.table>thead>tr>th {
        white-space: normal !important;
        padding: 5px;
    }
    .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
        padding: 1px;
    }
    .table-responsive {
        border: none;
    }
}

#ppTag {
    cursor: pointer !important;
    color: #007bff !important;
    text-decoration: none !important;
    background-color: transparent !important;
    -webkit-text-decoration-skip: objects !important;
}